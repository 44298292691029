import React, { useEffect, useRef } from 'react';
import { menuOpenAtom } from '../atoms';
import { useAtom } from 'jotai';
import { LocationContext } from '@reach/router';

type LocationChangeProviderProps = {
    children: React.ReactElement;
    locationContext: LocationContext;
}

const LocationChangeProvider: React.FC<LocationChangeProviderProps> = ({ children, locationContext }) => {
    const currentLocationRef = useRef('');
    const [menuOpen, setMenuOpen] = useAtom(menuOpenAtom);

    useEffect(() => {
        if (currentLocationRef.current !== locationContext.location.pathname) {
            setMenuOpen(false);
            currentLocationRef.current = locationContext.location.pathname;
        }
    }, [locationContext]);

    return children;
}

export default LocationChangeProvider;