// @ts-ignore
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Location } from '@reach/router';
import LocationChangeProvider from './src/components/LocationChangeProvider';

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) =>
    <QueryClientProvider client={queryClient}>
        <Location>
            {(locationContext) =>
                <LocationChangeProvider locationContext={locationContext}>
                    {element}
                </LocationChangeProvider>
            }
        </Location>
    </QueryClientProvider>